import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { openWidget } from 'aerosync-web-sdk';

function Aeropay() {
  const { aggregatorToken, userId } = useParams();
  const widgetRef = useRef(null);

  useEffect(() => {

    document.title = 'Link Bank Account with Aeropay';

    if (aggregatorToken) {
      widgetRef.current = openWidget({
        token: aggregatorToken,
        id: "widget",
        iframeTitle: "Connect",
        style: {
          width: '100%',
          height: '100%',
          bgColor: '#FFFFFF',
          opacity: 0.7
        },
        environment: "production",
        onEvent: function (type, payload) {
          console.log("onEvent", type, payload);
        },
        onLoad: function (event) {
          console.log("onLoad", event);
        },
        onSuccess: function (event) {
          console.log("onSuccess", event);
          console.log("success : " + "\n" + JSON.stringify(event, null, 4));

          var loaderAnimation = document.getElementById("widget");
          if (loaderAnimation) {
            loaderAnimation.innerHTML = "<div style=\"position: absolute; top: 0; left: 0; right: 0; bottom: 0; background: transparent url('/loader.gif') no-repeat fixed center;\"></div>";
          }

          // Send event data to another script using fetch
          fetch('https://api.agtekent.com/aeropay-success.php?userid=' + userId, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(event),
          })
          .then(response => {
              if (response.ok) {
                  window.location.replace("https://aeropay.agtekent.com/success.html");
              } else {
                  window.location.replace("https://aeropay.agtekent.com/failed.html");
              }
          });

        },
        onBankAdded: function (event) {},
        onError: function (event) {
          console.log("onError", event);
          var errorData = document.getElementById("wrapData");
          if (errorData) {
            errorData.innerHTML = "";
            errorData.innerText = "Error : " + "\n" + JSON.stringify(event, null, 4);
          }
        },
      });

      if (widgetRef.current) {
        widgetRef.current.launch();
      }
    }
  }, [aggregatorToken, userId]);

  return (
    <div>
      <div id="widget"></div>
      <div id="wrapData"></div>
    </div>
  )
}

export default Aeropay;
