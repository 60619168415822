import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Aeropay from './Aeropay';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/enroll/:aggregatorToken/:userId" element={<Aeropay />} />
      </Routes>
    </Router>
  )
}

export default App;
